define("ember-table/components/-private/row-wrapper", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/array", "ember-table/-private/utils/ember", "ember-table/-private/utils/array", "ember-table/-private/utils/observer", "@ember/template-factory"], function (_exports, _component, _object, _computed, _array, _ember, _array2, _observer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CellWrapper = _object.default.extend({
    /* eslint-disable-next-line ember/no-observers, ember-best-practices/no-observers */
    columnValueValuePathDidChange: (0, _observer.observer)('columnValue.valuePath', function () {
      let columnValuePath = (0, _object.get)(this, 'columnValue.valuePath');
      let cellValue = columnValuePath ? (0, _computed.alias)(`rowValue.${columnValuePath}`) : null;
      (0, _object.defineProperty)(this, 'cellValue', cellValue);
      (0, _ember.notifyPropertyChange)(this, 'cellValue');
    }),
    cellMeta: (0, _object.computed)('rowMeta', 'columnValue', function () {
      let rowMeta = (0, _object.get)(this, 'rowMeta');
      let columnValue = (0, _object.get)(this, 'columnValue');
      if (!rowMeta._cellMetaCache.has(columnValue)) {
        rowMeta._cellMetaCache.set(columnValue, _object.default.create());
      }
      return rowMeta._cellMetaCache.get(columnValue);
    })
  });
  const layout = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield this.api}}
  */
  {
    "id": "B4TDzzsE",
    "block": "[[[18,1,[[30,0,[\"api\"]]]]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "/Users/yibaebielliot/Documents/Software Development/Appknox/Repos/irene/ember-table/components/-private/row-wrapper.js",
    "isStrictMode": false
  });
  var _default = _exports.default = _component.default.extend({
    layout,
    tagName: '',
    canSelect: undefined,
    checkboxSelectionMode: undefined,
    columnMetaCache: undefined,
    columns: undefined,
    rowMetaCache: undefined,
    rowSelectionMode: undefined,
    rowToggleMode: undefined,
    rowValue: undefined,
    rowsCount: undefined,
    init() {
      this._super(...arguments);
      this._cells = (0, _array.A)([]);
    },
    destroy() {
      this._cells.forEach(cell => cell.destroy());
      this._super(...arguments);
    },
    api: (0, _object.computed)('rowValue', 'rowMeta', 'cells', 'canSelect', 'rowSelectionMode', 'rowToggleMode', 'rowsCount', function () {
      let rowValue = this.get('rowValue');
      let rowMeta = this.get('rowMeta');
      let cells = this.get('cells');
      let canSelect = this.get('canSelect');
      let rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      let rowToggleMode = this.get('rowToggleMode');
      let rowsCount = this.get('rowsCount');
      return {
        rowValue,
        rowMeta,
        cells,
        rowSelectionMode,
        rowToggleMode,
        rowsCount
      };
    }),
    rowMeta: (0, _object.computed)('rowValue', function () {
      let rowValue = this.get('rowValue');
      let rowMetaCache = this.get('rowMetaCache');
      return rowMetaCache.get(rowValue);
    }),
    cells: (0, _object.computed)('rowValue', 'rowMeta', 'columns.[]', 'canSelect', 'checkboxSelectionMode', 'rowSelectionMode', function () {
      let columns = this.get('columns');
      let numColumns = (0, _object.get)(columns, 'length');
      let rowValue = this.get('rowValue');
      let rowMeta = this.get('rowMeta');
      let rowsCount = this.get('rowsCount');
      let canSelect = this.get('canSelect');
      let checkboxSelectionMode = canSelect ? this.get('checkboxSelectionMode') : 'none';
      let rowSelectionMode = canSelect ? this.get('rowSelectionMode') : 'none';
      let {
        _cells
      } = this;
      if (numColumns !== _cells.length) {
        while (_cells.length < numColumns) {
          _cells.pushObject(CellWrapper.create());
        }
        while (_cells.length > numColumns) {
          _cells.popObject().destroy();
        }
      }
      _cells.forEach((cell, i) => {
        let columnValue = (0, _array2.objectAt)(columns, i);
        let columnMeta = this.get('columnMetaCache').get(columnValue);

        // eslint-disable-next-line ember/no-side-effects, ember-best-practices/no-side-effect-cp
        (0, _object.setProperties)(cell, {
          checkboxSelectionMode,
          columnMeta,
          columnValue,
          rowMeta,
          rowSelectionMode,
          rowValue,
          rowsCount
        });
      });
      return _cells;
    })
  });
});